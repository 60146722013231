import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../../context/users/UserContext'
import {FaFileExcel, FaPen, FaTimes} from 'react-icons/fa';
import {createQuestion, getQuestions, removeQuestion} from "../../context/questions/QuestionActions";
import AddQuestionToCanvas from "../canvas/AddQuestionToCanvas";
import AddPhotoToCanvas from "../canvas/AddPhototoCanvas";
import {getReport, getReports} from "../../context/photos/PhotoActions";
import {getReportSession, getSessions} from "../../context/users/UserActions";

// eslint-disable-next-line
export default function ReportList() {

    const [reports, setReports] = useState()
    const { users: sessions, authenticatedUser, setUsers, setAlert } = useUserContext()

    const [keywords, setKeywords] = useState('')
    const handleChangeKeywords = (e) => setKeywords(e.target.value)

    window.scrollTo({
        top: 0,
        behavior: 'auto',
    })

    useEffect(() => {
        const getPictures = async () => {
            const response = await getSessions({ path: 'sessions', token: authenticatedUser.value });
            const myValue = localStorage.getItem('alleanza_jwtoken');
            // console.log('Value from localStorage:', myValue);
            // console.log(response.data)
            setReports(response.data)
        }

        getPictures()
    }, [])

    const handleOnDownloadReport = async (sessionCode) => {
        const data = await getReportSession({ path: 'canvasReport', sessionCode, token: authenticatedUser.value })
        // console.log(data)

        const response = await getReport(data.data);

        if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
        }

    }

    return (
        <>
            <div className='gap-1 mt-3'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-2'>
                            <h5 className='card-title '>Reportes</h5>
                        </div>

                    </div>
                    <table className='table table-hover table-bordered  mt-3'>
                        <thead>
                        <tr>
                            <th scope='col'>Sesión ID</th>
                            <th scope='col'>Nombre de la sesión</th>
                            <th scope='col'>Fecha y hora de la sesión</th>
                            <th scope='col'>Descargar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reports && reports.length > 0 && reports.map((report, index) => (
                            <tr key={report.sessionId}>
                                <th scope='row'>{report.sessionCode}</th>
                                <td >
                                    {report.sessionName}
                                </td>
                                <td>{report.sessionDate}</td>
                                <td>
                                    <button
                                        onClick={() => handleOnDownloadReport(report.sessionCode)}
                                        className='btn'
                                        data-bs-title='This top tooltip is themed via CSS variables.'>
                                        <FaFileExcel />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/*<nav aria-label="Page navigation example" className={"d-flex  justify-content-end"}>*/}
                    {/*    <ul className="pagination">*/}
                    {/*        <li className="page-item"  onClick={prevPage}>*/}
                    {/*            <a className="page-link" href="#" aria-label="Previous">*/}
                    {/*                <span  aria-hidden="true">&laquo;</span>*/}
                    {/*                <span className="sr-only">Previous</span>*/}
                    {/*            </a>*/}
                    {/*        </li>*/}
                    {/*        {[...Array(maxPage)].map((_, index) => (*/}
                    {/*            <li key={index + 1} className={`page-item ${pag === index ? 'bg-primary' : ''}`} value={index}>*/}
                    {/*                <a className={`page-link ${pag === index ? 'bg-primary text-white' : ''}`} >{index + 1}</a>*/}
                    {/*            </li>*/}
                    {/*        ))}*/}
                    {/*        <li className="page-item" onClick={nextPage}>*/}
                    {/*            <a className="page-link"  aria-label="Next">*/}
                    {/*                <span aria-hidden="true">&raquo;</span>*/}
                    {/*                <span className="sr-only">Next</span>*/}
                    {/*            </a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</nav>*/}
                </div>


            </div>
        </>
    )
}
