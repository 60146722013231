const userReducer = (state, action) => {
  switch(action.type) {
    case 'AUTH_USER':
      return {
        ...state,
        authenticatedUser: action.payload,
        token: action.payload.token,
        isAuthenticated: true,
        loading: false,
      }
    case 'LOGOUT_USER':
      return {
        ...state,
        authenticatedUser: {},
        token: '',
        isAuthenticated: false,
        loading: false,
      }
      case 'GET_USERS':
        return {
          ...state,
          users: action.payload,
          loading: false,
        }
      case 'GET_USER':
        return {
          ...state,
          user: action.payload,
          loading: false,
        }
      case 'SET_SESSION':
        return {
          ...state,
          session: action.payload,
          loading: false,
        }
      case 'ADD_USER':
        return {
          ...state,
          newUser: action.payload,
          loading: false,
        }
      case 'SET_PASS':
        return {
          ...state,
          tempPass: action.payload,
          loading: false,
        }
      case 'SET_ALERT':
        return {
          ...state,
          msgType: action.payload.msgType,
          message: action.payload.message,
          link: action.payload.link,
          url: action.payload.url,
          showAlert: true,
          loading: false,
        }
      case 'SET_INDUSTRIES':
        return {
          ...state,
          industries: action.payload,
        }
      case 'SET_USES':
        return {
          ...state,
          uses: action.payload,
        }
      case 'DISABLE_ALERT':
        return {
          ...state,
          msgType: '',
          message: '',
          link: false,
          url: '',
          showAlert: false,
          loading: false,
        }
      case 'CLEAR_USERS':
        return {
          ...state,
          users: [],
        }
      case 'SET_MSG':
        return {
          ...state,
          message: action.payload,
          loading: false,
        }
    default:
      return state
  }
}

export default userReducer