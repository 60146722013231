import { useContext, useState, createContext, useReducer } from 'react'
import userReducer from './UserReducer'
import Cookies from 'js-cookie';
import {obtenerDominio} from "../../utils/utilsFunct";

const keyToken = process.env.REACT_APP_SESSION_KEY || 'alleanza_jwtoken'

const UserContext = createContext()

const initialState = {
  isAuthenticated: false,
  userData: {},
  users: [],
  user: {},
  session: null,
  industries: [],
  uses: [],
  tempPass: null,
  newUser: {},
  token: '',
  authenticatedUser: {},
  message: '',
  msgType: '',
  link: false,
  url: '',
  showAlert: false,
  loading: false,
}

const getToken = () => {

  const tokenString = localStorage.getItem(keyToken)
  const userToken = JSON.parse(tokenString)

  if(!userToken) { return null }
  if (Object.prototype.hasOwnProperty.call(userToken, 'expiry')) {
      if(new Date().getTime() > userToken.expiry) {
          localStorage.removeItem(keyToken)
          return null
      }
  }
  return userToken.value
}

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState)
  const [token, setToken] = useState(getToken())
  const userToken = localStorage.getItem('alleanza_jwtoken') ? JSON.parse(localStorage.getItem('alleanza_jwtoken')) : {}
  initialState.authenticatedUser = userToken
  initialState.isAuthenticated = localStorage.getItem('alleanza_jwtoken') ? true : false

  Cookies.set('keyToken', `${userToken.value}`, { domain: `.${obtenerDominio()}`});
  Cookies.set('appName', 'alleanza', { domain: `.${obtenerDominio()}`});

  const authenticateUser = (user) => {
    dispatch({ type: 'AUTH_USER', payload: user })
  }

  const logOutUser = (user) => {
    dispatch({ type: 'LOGOUT_USER', payload: user })
  }

  const setUsers = (users) => {
    dispatch({ type: 'GET_USERS', payload: users })
  }

  const setUser = (user) => {
    dispatch({ type: 'GET_USER', payload: user })
  }

  const setSession = (session) => {
    dispatch({ type: 'SET_SESSION', payload: session })
  }

  const setIndustries = (industries) => {
    dispatch({ type: 'SET_INDUSTRIES', payload: industries  })
  }

  const setUses = (uses) => {
    dispatch({ type: 'SET_USES', payload: uses })
  }

  const setAlert = (alert) => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
    dispatch({ type: 'SET_ALERT', payload: alert })
  }

  const disableAlert = () => {
    dispatch({ type: 'DISABLE_ALERT', payload: false })
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        token,
        setAlert,
        disableAlert,
        setToken,
        authenticateUser,
        logOutUser,
        setUsers,
        setUser,
        setSession,
        setIndustries,
        setUses,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => {
  return useContext(UserContext)
}
