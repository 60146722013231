import { useUserContext } from '../../context/users/UserContext';
import Alert from 'react-bootstrap/Alert'

const AlertComponent = () => {
  const { message, msgType, link = false, url, showAlert, disableAlert } = useUserContext()

  return (
    <Alert show={showAlert} variant={msgType}>
      <div className='row'>
        <div className='col-md-10'>
          {message} {link && <a href={url}>Encuesta</a>}
        </div>
        <div className='col d-flex justify-content-end'>
          <button type='button' className='btn-close' onClick={() => disableAlert()} aria-label='Close'></button>
        </div>
      </div>
      </Alert>
  )
}

export default AlertComponent
