import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useUserContext } from '../context/users/UserContext'
import { login, sendRestoreUrl, validatePermissions } from '../context/users/UserActions'
import Logo from '../components/layout/assets/logo.png'

const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { authenticateUser, isAuthenticated, setToken, setAlert } = useUserContext()

  const [username, setUsername] = useState('')
  const [password, setPass] = useState('')
  const [validEmail, setValidEmail] = useState(true)

  const handleChangeEmail = (e) => {
    setUsername(e.target.value)
    if(e.target.value === '') {
      setValidEmail(true)
    } else {
      setValidEmail(EMAIL_REGEX.test(e.target.value))
    }
  }

  const handleChangePass = (e) => setPass(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!validEmail) {
      setAlert({
        message: 'Por favor ingresa el correo electrónico con formato válido.',
        msgType: 'warning'
      })
    } else if(username === '' || password === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })

      let userData = await login({ path: 'users/login',
      data: {
        userEmail: username.trim(),
        userPass: password,
      }, 
      token: '' })

      if(Object.prototype.hasOwnProperty.call(userData, 'token')) {
        userData = {
          ...userData,
          value: userData.token
        }
        authenticateUser(userData)
        setToken(userData.token)
        navigate(validatePermissions(userData.permissions, 'session') ? '/sessions' : '/home')
      } else {
        setAlert({
          message: userData.message,
          msgType: 'warning'
        })
      }
    }
  }

  const handleRecoverPass = async (e) => {
    e.preventDefault()
    if(username === '') {
      setAlert({
        message: 'Para recuperar la contraseña, por favor ingresa el correo.',
        msgType: 'warning'
      })
    } else {
      const userData = await sendRestoreUrl(username.trim())

      if(Object.prototype.hasOwnProperty.call(userData, 'error')) {
        setAlert({
          message: 'El usuario no fue notificado porque hubo un error en el servicio de notificaciones. Favor de contactar a soporte.',
          msgType: 'warning'
        })
      } else if (userData.includes('Hemos enviado un link para recuperar tu contraseña a tu correo:')) {
        const email = userData.replace('Hemos enviado un link para recuperar tu contraseña a tu correo: ', '')
        setAlert({
          message: `Hemos enviado un correo a ${email} para recuperar tu contraseña.`,
          msgType: 'success'
        })
      } else {
        setAlert({
          message: userData,
          msgType: 'danger'
        })
      }
      setUsername('')
      setPass('')
    }
  }

  const setMessage = async () => {
    setAlert({
      message: 'Este sistema se encuentra en versión Beta por lo que puede presentar algunos desperfectos o funcionalidad aún no implementada en su totalidad.',
      msgType: 'warning'
    })
  }

  useEffect(() => {
    setMessage()
  }, [])

  return (
    <>
      {!isAuthenticated ? (
        <div className='Auth-form-container-full'>
          <form onSubmit={handleSubmit} className='Auth-form'>
            <div className='Auth-form-content'>
              <div className='form-group mt-3 text-center'>
                <img
                  src={Logo}
                  alt='Loading...'
                />
              </div>
              <div className='form-group mt-3'>
                <label>Anfitrión:</label>
                <input
                  type='email'
                  className='form-control mt-1'
                  placeholder='Correo electrónico'
                  value={username}
                  onChange={handleChangeEmail}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Contraseña:</label>
                <input
                  type='password'
                  className='form-control mt-1'
                  placeholder='Contraseña'
                  value={password}
                  onChange={handleChangePass}
                />
              </div>
              <div className='d-grid gap-2 mt-3'>
                <button type='submit' className='btn btn-primary btn-block mb-3'>
                  Ingresar como anfitrión
                </button>
              </div>

              <div className='container'>
                <div className='row'>
                  <div className='col'>
                  <button type='submit' className='btn' data-bs-toggle='button' onClick={handleRecoverPass}>
                    Olvidé mi contraseña
                  </button>
                  </div>
                  <div className='col'>
                    <p className='text-end'>
                      <a href='/hostUser'>Quiero comprar </a>
                      <span data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip with svg">
                        <i data-feather="help-circle"></i>
                      </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                          <title>Adquirir licencias para utilizar la plataforma</title>
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                        </svg>
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col text-end'>
                    <p className='text-end'>
                      <a href='/hostUserCode'>Quiero Registrarme </a>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                        <title>Tengo una licencia y quiero ingresar</title>
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                      </svg>
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col text-end'>
                    <p className='text-end'>
                      <a href='/getPlan?plan=1&licenses=0'>Plan Freemium</a>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                        <title>Émula el redireccionamento para adquirir un plan freemium desde una página externa</title>
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                      </svg>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : 
      (
        <Navigate replace to={'/home'} />
      )}
    </>
  )
}
